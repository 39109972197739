import React, { Component } from 'react';
import AuthService from '../../components/AuthService'



const Auth = new AuthService();

const title={
    backgroundColor:"blue",
    color:"#f5f5f5",
    fontWeight: "bold",
    fontSize: "x-large"
}

const text={
    margin: "auto 5%",
    lineHeight: "2em",
    textAlign: "justify",
    textIndent: "3em"
}

class Conditions extends Component{

  constructor() {
    super();
  
  }

  

 

    render(){
     
    
          return (
            <React.Fragment>
                <div className="container">
                    <div className="post-content">
                        <div className="post-container" style={title}>
                            AGREEMENT ACCEPTANCE
                        </div>
                        <div className="post-container" style={text}> 
                        Your use and access of this website ("Rotate-Pro.com") is subject entirely to these Terms and Conditions. You will not use the Website for any purpose that is prohibited or unlawful by these Terms and Conditions. In using the Website, you are entirely accepting the disclaimer, terms and conditions contained in this notice. Do not use this Website if you do not accept these Terms and Conditions. 
                        </div>
                    </div>

                    <div className="post-content">
                        <div className="post-container" style={title}>
                            WEBSITE USAGE
                        </div>
                        <div className="post-container" style={text}> 
                        Minors under the age of 18 shall are prohibited to register as a User of this website and are not allowed to transact or use the website.                        
                        </div>
                    </div>

                    <div className="post-content">
                        <div className="post-container" style={title}>
                            WEBSITE, SOFTWARE, AND SERVICES CHANGES
                        </div>
                        <div className="post-container" style={text}> 
                        Rotate-Pro has the right to reserve: 
                        <br />
                        <ol type="a">
                        <li>
Any changes or removal whether temporarily or permanently of the Website or any part of the Website without notice. You confirm that Rotate-Pro shall not be liable to you for any such changes or removal. 
</li>
<li>

Any changes, removal, or discontinue of any services, software’s, promotions, licensing, or pricing as advertised on this Website at any time without notice. You confirm that Rotate-Pro shall not be liable for any such changes or removal. 

</li>
<li>

Any changes or discontinue any promotional discounted vouchers at any time with notice and you confirm that Rotate-Pro shall not be liable for any such changes or removal. 
</li>
<li>

Any changes of this Agreement at any time, and your continued use of this Website following any changes shall be deemed to be your acceptance of such any changes.                         
</li>
                    </ol>
                    </div>
                    </div>

                    <div className="post-content">
                        <div className="post-container" style={title}>
                            METHOD OF PAYMENT
                        </div>
                        <div className="post-container" style={text}> 
                        Rotate-Pro has the right to reserve: <br />
                        <ol type="a">
                        <li>
If you make a payment for our products or services on our Website, the details you are asked to submit will be provided directly to our payment provider via a secured connection. 
</li>
<li>
The cardholder must retain a copy of transaction records and Merchant policies and rules.                         
</li>   
</ol>
                        </div>
                    </div>

                    <div className="post-content">
                        <div className="post-container" style={title}>
                            DELIVERY/SHIPMENT
                        </div>
                        <div className="post-container" style={text}> 
                        Rotate-Pro will NOT deal or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with the law of UAE. Multiple shipments/delivery may result in multiple postings to the cardholder’s monthly statement.                         
                        </div>
                    </div>

                    <div className="post-content">
                        <div className="post-container" style={title}>
                            LINKS TO THIRD PARTY WEBSITES 
                        </div>
                        <div className="post-container" style={text}> 
                        The Website may contain links or other sites controlled and maintained by a third party. Any links redirected to other websites are not an endorsement of such other sites. You agree and acknowledge that we are not responsible for such availability of any websites.                    
                        </div>
                    </div>

                    <div className="post-content">
                        <div className="post-container" style={title}>
                            DELIVERY/SHIPMENT
                        </div>
                        <div className="post-container" style={text}> 
                        Rotate-Pro will NOT deal or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with the law of UAE. Multiple shipments/delivery may result in multiple postings to the cardholder’s monthly statement.                         </div>
                    </div>

                    <div className="post-content">
                        <div className="post-container" style={title}>
                            COPYRIGHT
                        </div>
                        <div className="post-container" style={text}> 
                            <p>
                                The Intellectual Property Rights in this website, the materials on or accessible thru it belong to Rotate-Pro and/or to its licensors. This Intellectual Property Rights, the materials on or accessible thru this website may not be copied, distributed, published, licensed, used or reproduced in anyway. 
                            </p>
                            <p>
                                Rotate-Pro name and the Rotate-Pro logo are trademarks owned and belong to Rotate-Pro and is not to be used, reproduced or copied in anyway without written consent from Rotate-Pro.                     
                            </p>
                        </div>
                    </div>

                    <div className="post-content">
                        <div className="post-container" style={title}>
                            LIMITATION OF LIABILITY 
                        </div>
                        <div className="post-container" style={text}> 
                            <p>
                                The Website is created on an “AS IS” and “AS AVAILABLE” basis without any representation or endorsement made and without warranty of any kind whether expressed or implied, including but not limited to the implied warranties of satisfactory quality, fitness for a particular purpose, non-infringement, compatibility, security and accuracy. 
                            </p>
                            <p>
                                To the degree permitted by law, Rotate-Pro will not be liable for any indirect or consequential loss or damage whatever (includes without limitation loss of opportunity, loss of business, data, and profits) arising out of or in connection with the use of the Website. Rotate-Pro makes no warranty that the functionality of the Website will be continuous or error free, that defects will be corrected or that the Website or the server that makes it available are free of viruses or anything else which may be harmful or damaging. Nothing in these Terms and Conditions shall be construed so as to exclude or limit the liability of Rotate-Pro for death or personal injury as a result of the negligence of Rotate-Pro or that of its employees or agents.                    
                            </p>
                        </div>
                    </div>


                    <div className="post-content">
                        <div className="post-container" style={title}>
                            INDEMNITY
                        </div>
                        <div className="post-container" style={text}> 
                        In this Agreement you agree to indemnify and hold Rotate-Pro and its employees and agents harmless from and against all liabilities, damages, losses, legal fees, costs and other expenses in relation to any claims or actions brought against Rotate-Pro arising out of any breach by you of these Terms and Conditions or other liabilities that goes out during your use of this Website. 
                        </div>
                    </div>


                    <div className="post-content">
                        <div className="post-container" style={title}>
                            SEVERABILITY
                        </div>
                        <div className="post-container" style={text}> 
                        In the event that any provision of this Agreement is declared by any judicial or any competent authority to be voidable, void, illegal or otherwise unenforceable or indications of the same are received by either you or us from any relevant competent authority, we shall modify that provision in such a reasonable manner as to achieve the intention of all the parties without illegality or, at our discretion, such provision may be severed from this Agreement and the remaining provisions of this Agreement shall remain in full force and effect. 
                        </div>
                    </div>

                    <div className="post-content">
                        <div className="post-container" style={title}>
                            APPLICABLE LAW AND DISPUTE
                        </div>
                        <div className="post-container" style={text}> 
                        This Agreement and all matters arising from this Website shall have exclusive jurisdiction from courts governed by and construed in accordance with the laws of United Arab Emirates over all the disputes arising and pertaining of this Agreement. United Arab of Emirates is our country of domicile.                         </div>
                    </div>

                    <div className="post-content">
                        <div className="post-container" style={title}>
                              ENTIRE AGREEMENT 
                        </div>
                        <div className="post-container" style={text}> 
                            <p>
                                These terms and conditions together with any documents expressly referred to in them, contain the entire Agreement between us relating to the subject matter covered and superseded from any previous agreements, arrangements, undertakings or proposals, may it be written or oral in relation to such matters. No oral information or oral explanation performed by any party shall alter the interpretation on any of these terms and conditions. 
                            </p>
                            <p>
                                In agreeing in these terms and conditions you have not confide from any representation other than remaining in what has been expressed within the context of this Agreement. In such cases of misinterpretation in the context of this Agreement you agree that you shall have no remedy whatsoever from miscommunicating contents in these Terms and Conditions which has not made expressly included in this Agreement. 
                            </p>
 
 
                            <p>
                                Rotate-Pro reserves the right to add, delete, or modify any provision in this Agreement at any time without any notice.                         
                            </p>
                        </div>
                    </div>
                  

                    

                
                </div>
            </React.Fragment>
        )
      
    }
}

export default Conditions;