import React, { Component } from "react";
import AuthService from "../components/AuthService";
//import { createHashHistory } from 'history'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//const history = new createHashHistory();

const Auth = new AuthService();

function InvitRows(props) {
  const user = props.user;
  const acceptInvit = props.acceptInvit;
  const deleteInvit = props.deleteInvit;
  const link = "#/Profile/About?id=" + user.user_id;

  return (
    <li>
      <a style={{ display: "inline-flex", cursor: "pointer" }}>
        <div style={{ margin: "auto 1rem auto auto" }}>
          <div>
            <a href={link} style={{ marginRight: "1%", lineHeight: "3" }}>
              {user.first_name} {user.last_name}
            </a>
          </div>
          <div>
            <a
              style={{ marginRight: "10%", color: "#00B94E" }}
              id={user.first_name + " " + user.last_name}
              onClick={(e) => {
                acceptInvit(e, user.invitUrl);
              }}
            >
              Accept
            </a>{" "}
            <a
              onClick={(e) => {
                deleteInvit(e, user.invitUrl);
              }}
              style={{ marginRight: "1%", color: "#F12623" }}
            >
              Delete
            </a>
          </div>
        </div>
        <div>
          <img
            className="profile-photo"
            src={
              user.photo
                ? user.photo
                : "public_assets/images/users/defaultAvatar.png"
            }
          />
        </div>
      </a>
    </li>
  );
}

function SuggetionsRows(props) {
  const user_auth = props.suggestion;
  const update = props.update;
  const link = "#/Profile/About?id=" + user_auth.user_id;

  return (
    <li>
      <a href={link} onClick={update}>
        <div className="follow-user">
          <img
            src={user_auth.photo}
            alt=""
            className="profile-photo-sm pull-left"
          />
          <div>
            <h5>
              {user_auth.first_name} {user_auth.last_name}
            </h5>
            <div className="text-green">{user_auth.email}</div>
          </div>
        </div>
      </a>
    </li>
  );
}

async function fetchSearch(name) {
  const user_auth = await Auth.searchUsersByFirst_name(name);
  var array = [];
  const count = "http://apip1.rotate-pro.com/api/Users/".length;
  for (var i = 0; i < user_auth.length; i++) {
    const y = user_auth[i].url.substring(count, user_auth[i].url.length - 1);
    const yy = user_auth[i];
    const user_info = await Auth.getUserInfoById(y);
    const z = await user_info[0];
    const x = await Object.assign({}, yy, z);

    array.push(x);
  }

  return array;
}

class HeaderSection extends Component {
  constructor() {
    super();
    this.state = {
      searchSuggestion: [],
      test: true,
      invitations: [],
      user: {},
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deleteInvit = this.deleteInvit.bind(this);
    this.acceptInvit = this.acceptInvit.bind(this);
    this.getInvit = this.getInvit.bind(this);
  }

  update() {
    // window.location.reload(true)
  }

  handleLogout(e) {
    e.preventDefault();
    Auth.logout();
    var xx = setInterval(timerRedirect.bind(this), 1500);
    //history.replace('/Login')

    function timerRedirect(history) {
      var x = localStorage.getItem("id_token");
      if (x) {
      } else {
        this.props.history.push("/Login");
        clearInterval(xx);
        // history.replace('/Login')
      }
    }
  }

  handleChange(e) {
    const value = e.target.value;

    if (value.length > 1) {
      fetchSearch(value).then((res) => {
        this.setState({
          searchSuggestion: res,
        });
      });
    }
  }

  async getInvit() {
    var array = [];

    const id = this.state.user.user_id;

    const x = await Auth.getUserFriendsRequestsById(id);

    for (var i = 0; i < x.length; i++) {
      const id2 = x[i].friend1;
      const url = x[i].url;
      if (x[i].friend1 != id) {
        const auth = await Auth.getUserById(id2);
        const info = await Auth.getUserInfoById(id2);
        const invitUrl = url;

        const temp = Object.assign(auth, info[0]);
        array.push(temp);
        array[array.length - 1].invitUrl = invitUrl;
      }
    }
    return array;
  }

  async acceptInvit(e, url) {
    e.preventDefault();
    const name = e.target.id;
    var test = true;
    Auth.acceptInvit(url)
      .then(() => {
        this.notifyFreindAdded(name);
        this.state.invitations.splice(this.search(url, this.state.invitations));
        this.forceUpdate();
      })
      .catch((e) => {
        test = false;
      });
  }

  search(nameKey, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].invitUrl === nameKey) {
        return i;
      }
    }
  }

  async deleteInvit(e, url) {
    e.preventDefault();
    await Auth.deleteInvit(url);
  }

  componentWillMount() {
    this.state.user =
      this.props.user != undefined ? this.props.user : Auth.getProfile();

    this.getInvit().then((res) => {
      this.setState({
        invitations: res,
      });
    });
  }

  notifyFreindAdded = (friend) =>
    toast.success(friend + " has been added to your connections :)", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  render() {
    var suggestionsList = this.state.searchSuggestion.filter(
      (suggestion) => 0 < 10
    );
    var InvitList = this.state.invitations.filter((invit) => 0 < 10);

    return (
      <React.Fragment>
        <header id="header">
          <nav className="navbar navbar-default navbar-fixed-top menu">
            <div className="container">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  data-target="#bs-example-navbar-collapse-1"
                  aria-expanded="false"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <a className="navbar-brand" href="/#/">
                  <img
                    src={"public_assets/old_images/FC2_logo_light.png"}
                    alt="logo"
                  />
                </a>
              </div>

              <div
                className="collapse navbar-collapse"
                id="bs-example-navbar-collapse-1"
              >
                <ul className="nav navbar-nav navbar-right main-menu">
                  {/* <li className="dropdown-toggle" data-toggle="dropdown"><a href="/#/Newsfeed"><FontAwesomeIcon color="red" icon={faBell} /></a></li>
                                    <ul className="dropdown-menu" style={{listStyleType:'none'}}>
                                        {notifList.map((notif, index) =>
                                            <NotifsRows notif={notif} />
                                        )}
                                    </ul> */}

                  <li className="dropdown">
                    <a
                      href="#"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <FontAwesomeIcon icon={faUserFriends} />{" "}
                      {this.state.invitations.length}
                    </a>
                    <ul
                      className="dropdown-menu newsfeed-home"
                      style={{ maxHeight: "500rem", overflowY: "auto" }}
                    >
                      <li>
                        <h5 style={{ marginLeft: "1rem" }}>Friend requests</h5>
                      </li>
                      {InvitList.map((invit, index) => (
                        <InvitRows
                          user={invit}
                          acceptInvit={this.acceptInvit}
                          deleteInvit={this.deleteInvit}
                        />
                      ))}
                    </ul>
                  </li>
                  <li className="dropdown">
                    <a href="/#/Newsfeed">Newsfeed</a>
                  </li>
                  <li className="dropdown">
                    <a href="/#/ArticlesPage">Library</a>
                  </li>
                  <li className="dropdown">
                    <a
                      href="#"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Profile{" "}
                      <span>
                        <img src="public_assets/images/down-arrow.png" alt="" />
                      </span>
                    </a>
                    <ul className="dropdown-menu newsfeed-home">
                      <li>
                        <a href="/#/Profile/About">About</a>
                      </li>
                      <li>
                        <a href="/#/Profile/Articles">My library</a>
                      </li>
                      <li>
                        <a href="/#/Profile/Friends">Connections</a>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <a href="/#/Login" onClick={this.handleLogout}>
                      Logout
                    </a>
                  </li>
                </ul>
                <form className="navbar-form navbar-right hidden-sm">
                  <div className="form-group">
                    <i className="icon ion-android-search"></i>
                    <input
                      type="text"
                      className="form-control dropdown-toggle"
                      data-toggle="dropdown"
                      placeholder="Search for users by name"
                      onChange={this.handleChange}
                    />
                    <ul className="dropdown-menu newsfeed-home">
                      {suggestionsList.map((suggestion, index) => (
                        <SuggetionsRows
                          update={this.update.bind(this)}
                          key={index}
                          suggestion={suggestion}
                        />
                      ))}
                    </ul>
                  </div>
                </form>

                {/* <form className="navbar-form navbar-right hidden-sm">
                                <div className="form-group">
                                    <i className="icon ion-android-search"></i>
                                    <input type="text" className="form-control" placeholder="Search friends, photos, videos">
                                </div>
                            </form> */}
              </div>
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

export default HeaderSection;
