import React, { Component } from "react";
import AuthService from "./AuthService";
import { HashRouter, Route, Switch } from "react-router-dom";
import Home from "../views/home/home";

import { createHashHistory } from "history";

const history = new createHashHistory();

export default function withAuth(AuthComponent) {
  //https
  const Auth = new AuthService("http://apip1.rotate-pro.com");

  return class AuthWrapped extends Component {
    constructor() {
      super();
      this.state = {
        user: null,
      };
    }

    componentWillMount() {
      if (!Auth.loggedIn()) {
        var path = history.location.pathname;
        var index = -1;
        var count = 0;
        do {
          index = path.indexOf("/");

          if (index > -1) {
            path = path.replace("/", "");

            count++;
          }
        } while (index > -1);

        path = history.location.pathname.substring(0, "/resetPassword/".length);

        if (count === 3 && path === "/resetPassword/") {
        } else {
          history.replace("/Login");
        }
      } else {
        try {
          const profile = Auth.getProfile();
          this.setState({
            user: profile,
          });
        } catch (err) {
          Auth.logout();
          history.replace("/Login");
        }
      }
    }

    render() {
      if (this.state.user != null) {
        return (
          <AuthComponent history={this.props.history} user={this.state.user} />
        );
      } else {
        return <AuthComponent history={this.props.history} />;
        // return null
      }
    }
  };
}
