import React from "react";
import Loadable from "react-loadable";

function Loading() {
  return (
    <div id="spinner-wrapper">
      <div className="spinner"></div>
    </div>
  );
}

const Newsfeed = Loadable({
  loader: () => import("./views/newsfeed/newsfeed.jsx"),
  loading: Loading,
});

const ProfilePage = Loadable({
  loader: () => import("./views/profile/page"),
  loading: Loading,
});

const TimeLine = Loadable({
  loader: () => import("./views/profile/timeline"),
  loading: Loading,
});

const About = Loadable({
  loader: () => import("./views/profile/about"),
  loading: Loading,
});

const Articles = Loadable({
  loader: () => import("./views/profile/articles"),
  loading: Loading,
});

const Friends = Loadable({
  loader: () => import("./views/profile/friends"),
  loading: Loading,
});

const Chatroom = Loadable({
  loader: () => import("./views/chatroom/chatroom"),
  loading: Loading,
});

// const RichTextExample = Loadable({
//   loader: () => import('./views/newsfeed/editor.js'),
//   loading: Loading,
// });

const NewArticle = Loadable({
  loader: () => import("./views/newsfeed/NewArticle"),
  loading: Loading,
});

const People = Loadable({
  loader: () => import("./views/people/people.jsx"),
  loading: Loading,
});

const ArticleView2 = Loadable({
  loader: () => import("./views/profile/ArticleView2.js"),
  loading: Loading,
});

const ArticlesPage = Loadable({
  loader: () => import("./views/articles/articles"),
  loading: Loading,
});

const Conditions = Loadable({
  loader: () => import("./views/home/conditions.js"),
  loading: Loading,
});

const routes = [
  { path: "/Newsfeed", exact: true, name: "Newsfeed", component: Newsfeed },
  {
    path: "/ProfilePage",
    exact: true,
    name: "ProfilePage",
    component: ProfilePage,
  },
  {
    path: "/Profile/Timeline",
    exact: true,
    name: "TimeLine",
    component: TimeLine,
  },
  { path: "/Profile/About", exact: true, name: "About", component: About },
  {
    path: "/Profile/Articles",
    exact: true,
    name: "Articles",
    component: Articles,
  },
  {
    path: "/Profile/Friends",
    exact: true,
    name: "Friends",
    component: Friends,
  },
  { path: "/Chatroom", exact: true, name: "Chatroom", component: Chatroom },
  { path: "/People", exact: true, name: "People", component: People },
  { path: "/Editor", exact: true, name: "NewArticle", component: NewArticle },
  {
    path: "/ArticleView",
    exact: true,
    name: "ArticleView2",
    component: ArticleView2,
  },
  {
    path: "/ArticlesPage",
    exact: true,
    name: "ArticlesPage",
    component: ArticlesPage,
  },
  {
    path: "/Conditions",
    exact: true,
    name: "Conditions",
    component: Conditions,
  },
];

export default routes;
