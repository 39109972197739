import Config from "../config/config.json";
import Axios from "axios";

const APINDPOINT = `${Config.API_END_POINT}articles/`;

Axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) console.log("unexpected", error);
  return Promise.reject(error);
});

const getArticles = async () => {
  const articles = await Axios.get(
    `${Config.API_END_POINT_SSL}articles/?page_size=50`
  );
  return { articles: articles.data.results };
};

const getLatestArticles = async () => {
  const articles = await Axios.get(
    `${Config.API_END_POINT_SSL}articles/?page_size=3`
  );
  return { articles: articles.data.results };
};

const getArticleUID = (url) => {
  return url.substring(APINDPOINT.length, url.length - 1);
};

export default {
  getArticles,
  getArticleUID,
  getLatestArticles,
};
