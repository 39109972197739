import React, { Component } from "react";
import logo from "./logo.svg";

import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import Home from "./views/home/home";
import DefaultLayout from "./containers/defaultLayout";
import Conditions from "./views/home/conditions";
import WithAuth from "./components/withAuth";
import resetPassword from "./views/home/resetPassword";

import "./App.css";
import "./assets/public_assets/imported/css/bootstrap.min.css";
import "./assets/public_assets/imported/css/style.css";
import "./assets/public_assets/imported/css/ionicons.min.css";
import "./assets/public_assets/imported/css/font-awesome.min.css";

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/Login" name="Login Page" component={Home} />
          <Route
            exact
            path="/resetPassword/:one/:two"
            name="reset password Page"
            component={resetPassword}
          />
          <Route
            exact
            path="/Conditions"
            name="Conditions Page"
            component={Conditions}
          />

          {/* <Route exact path="/Profile" name="Profile Page" component={ProfilePage} /> */}
          <Route
            path="/"
            name="Home"
            render={(props) => (
              <DefaultLayout {...props} user={this.props.user} />
            )}
          />
        </Switch>
      </HashRouter>
    );

    // return (
    //   <div className="App">
    //     <header className="App-header">
    //       <img src={logo} className="App-logo" alt="logo" />
    //       <h1 className="App-title">Welcome to React</h1>
    //     </header>
    //     <p className="App-intro">
    //       To get started, edit <code>src/App.js</code> and save to reload.
    //     </p>
    //   </div>
    // );
  }
}

export default WithAuth(App);
//export default App;
