import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import routes from "../routes";
import HeaderSection from "./header";
import FooterSection from "./footer";

import AuthService from "../components/AuthService";

const Auth = new AuthService();

class DefaultLayout extends Component {
  componentDidMount() {
    if (this.props.user) {
      const link =
        this.props.location.pathname + "" + this.props.location.search;
      const userLink =
        "http://apip1.rotate-pro.com/api/Users/" +
        this.props.user.user_id +
        "/";
      Auth.save_views_log(link, userLink);
    }
  }

  componentDidUpdate() {
    if (this.props.user) {
      const link =
        this.props.location.pathname + "" + this.props.location.search;
      const userLink =
        "http://apip1.rotate-pro.com/api/Users/" +
        this.props.user.user_id +
        "/";
      Auth.save_views_log(link, userLink);
    }
  }

  render() {
    return (
      <div>
        <HeaderSection {...this.props} />

        <Switch>
          {routes.map((route, idx) => {
            return route.component ? (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={(props) => (
                  <route.component {...props} user={this.props.user} />
                )}
              />
            ) : null;
          })}
          <Redirect from="/" to="/Newsfeed" />
        </Switch>

        <FooterSection {...this.props} />
      </div>
    );
  }
}

export default DefaultLayout;
