import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import articleServices from "../../../services/articles";
import DocPDFViewer from "../../../components/DocPDFViewer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    marginRight: 50,
    marginLeft: 50,
  },
  description: {
    fontSize: 14,
  },
}));

export default function ArticleAccordion() {
  const classes = useStyles();
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    const getArticles = async () => {
      const response = await articleServices.getLatestArticles();
      console.log(response);
      setArticles(response.articles);
    };

    getArticles();
  }, []);

  return (
    <div className={classes.root}>
      {articles.length > 0
        ? articles.map((article, key) => (
            <Accordion key={key}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {article.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.description}>
                  {article.small_description}
                  <div
                    style={{
                      color: "white",
                      backgroundColor: "#FAAE3A",
                      fontSize: 15,
                      padding: 10,
                      borderRadius: 5,
                    }}
                  >
                    You can only view 2 pages of this document, If you would
                    like to see the full version you may create an account and
                    connect.
                  </div>
                  <div style={{ margin: 5 }}>
                    {article.media_file != null ? (
                      <DocPDFViewer article={article} publiched={true} />
                    ) : null}
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))
        : null}
    </div>
  );
}
