import React, { Component } from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
class FooterSection extends Component {
  render() {
    return (
      <React.Fragment>
        <footer id="footer">
          <div className="container">
            <div className="row">
              <div className="footer-wrapper">
                <div className="col-md-3 col-sm-3">
                  <a href="">
                    <img
                      src={"public_assets/old_images/FC2_logo_dark.png"}
                      alt=""
                      className="footer-logo"
                    />
                  </a>
                  <h5>Share with your friends</h5>
                  <ul className="list-inline social-icons">
                    <li>
                      <TwitterShareButton
                        url="https://rotate-pro.com"
                        title="rotate-pro title"
                      >
                        <TwitterIcon size={32} round={true} />
                      </TwitterShareButton>
                    </li>
                    <li>
                      <LinkedinShareButton
                        url="https://rotate-pro.com"
                        title="rotate-pro title"
                      >
                        <LinkedinIcon size={32} round={true} />
                      </LinkedinShareButton>
                    </li>
                    <li>
                      <FacebookShareButton
                        url="https://rotate-pro.com"
                        title="rotate-pro title"
                      >
                        <FacebookIcon size={32} round={true} />
                      </FacebookShareButton>
                    </li>
                    <li>
                      <WhatsappShareButton
                        url="https://rotate-pro.com"
                        title="rotate-pro title"
                      >
                        <WhatsappIcon size={32} round={true} />
                      </WhatsappShareButton>
                    </li>
                  </ul>
                </div>
                <div className="col-md-2 col-sm-2">
                  {/* <h6>For individuals</h6>
                            <ul className="footer-links">
                                <li><a href="">Signup</a></li>
                                <li><a href="">login</a></li>
                                <li><a href="">Explore</a></li>
                                <li><a href="">Finder app</a></li>
                                <li><a href="">Features</a></li>
                                <li><a href="">Language settings</a></li>
                            </ul> */}
                </div>
                <div className="col-md-2 col-sm-2">
                  {/* <h6>For businesses</h6>
                            <ul className="footer-links">
                                <li><a href="">Business signup</a></li>
                                <li><a href="">Business login</a></li>
                                <li><a href="">Benefits</a></li>
                                <li><a href="">Resources</a></li>
                                <li><a href="">Advertise</a></li>
                                <li><a href="">Setup</a></li>
                            </ul> */}
                </div>
                <div className="col-md-2 col-sm-2">
                  {/* <h6>About</h6>
                            <ul className="footer-links">
                                <li><a href="">About us</a></li>
                                <li><a href="">Contact us</a></li>
                                <li><a href="">Privacy Policy</a></li>
                                <li><a href="">Terms</a></li>
                                <li><a href="">Help</a></li>
                            </ul> */}
                </div>
                <div className="col-md-3 col-sm-3">
                  {/* <h6>Contact Us</h6> */}
                  <ul className="contact">
                    {/* <li><i className="icon ion-ios-telephone-outline"></i>+971 4 420 1038</li> */}
                    <li>
                      <i className="icon ion-ios-email-outline"></i>
                      contact@rotate-pro.com
                    </li>
                    {/* <li><i className="icon ion-ios-location-outline"></i>PO48748 Dubai Marina Appart G06</li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright">
            <p>Copyright @OP2MUM 2019. All rights reserved</p>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default FooterSection;
