import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Button, Row, Col } from "reactstrap";
import Loader from "../assets/interactive_writing.gif";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const ArticleLoader = () => {
  return <img style={{ marginRight: "20%", marginLeft: "20%" }} src={Loader} />;
};

export default function DocPDFViewer({ article, publiched }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(1.0);

  function zoomIn() {
    setPageScale(pageScale + 0.1);
  }

  function zoomOut() {
    setPageScale(pageScale - 0.1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(publiched ? 2 : numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  console.log(article);

  return (
    <React.Fragment>
      <div>
        <div>
          <Row>
            <Col md={4}>
              <p style={{ fontWeight: "bold" }}>
                Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
              </p>
            </Col>
            <Col md={2}>
              <Button
                disabled={pageNumber <= 1}
                onClick={previousPage}
                color="success"
              >
                Previous
              </Button>
            </Col>
            <Col md={2}>
              <Button
                disabled={pageNumber >= numPages}
                onClick={nextPage}
                color="info"
              >
                Next
              </Button>
            </Col>
          </Row>
        </div>

        <div>
          <Row>
            <Col md={4}>
              <p style={{ fontWeight: "bold" }}>
                Page Scale{" "}
                {pageScale ? pageScale.toFixed(1) : "detecting View Scale"}
              </p>
            </Col>
            <Col md={2}>
              <Button
                disabled={pageScale < 0.5}
                onClick={zoomOut}
                color="success"
              >
                Zoom Out
              </Button>
            </Col>
            <Col md={2}>
              <Button disabled={pageScale >= 5} onClick={zoomIn} color="info">
                Zoom In
              </Button>
            </Col>
          </Row>
        </div>
        <Document
          file={article.media_file}
          onLoadSuccess={onDocumentLoadSuccess}
          renderMode="svg"
          loading={<ArticleLoader />}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              overflow: "scroll",
              //   marginRight: "20%",
              //   marginLeft: "20%",
            }}
          >
            <Page pageNumber={pageNumber} scale={pageScale} />
          </div>
        </Document>
      </div>
    </React.Fragment>
  );
}
