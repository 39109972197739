import React, { Component } from "react";
import { createHashHistory } from "history";

import AuthService from "../../components/AuthService";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import ArticleAccordion from "./components/articleAccordion";

import googlePlay from "../../assets/gplay.png";
import appStore from "../../assets/appstore.png";

const history = createHashHistory();

var country_list = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua & Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia & Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote D Ivoire",
  "Croatia",
  "Cruise Ship",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Polynesia",
  "French West Indies",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kuwait",
  "Kyrgyz Republic",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Pierre & Miquelon",
  "Samoa",
  "San Marino",
  "Satellite",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "St Kitts & Nevis",
  "St Lucia",
  "St Vincent",
  "St. Lucia",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor L'Este",
  "Togo",
  "Tonga",
  "Trinidad & Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks & Caicos",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (US)",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

const customStyles = {
  control: (base, state) => ({
    ...base,
    borderRadius: "30px",
  }),
  menu: (base) => ({
    ...base,
    color: "black",
  }),
};
// function ErrorMsg(props) {
//     return(
//         <div>
//             Please check these informations : <br />
//             Fill in all the fields <br />
//             Your email address <br />
//             The password must have 8 letters at least <br />
//             The password must not be common
//         </div>
//     )
// }

function ErrorMsg(props) {
  const name = props.name;
  return <div>{name}</div>;
}

class LoginSection extends Component {
  constructor() {
    super();
    this.state = { form: 1 };

    this.handleLogin = this.handleLogin.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.Auth = new AuthService();
    this.handleChangeForm = this.handleChangeForm.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.notifyErrorRegister = this.notifyErrorRegister.bind(this);
    this.notifySuccessRegister = this.notifySuccessRegister.bind(this);
    this.handleResetPass = this.handleResetPass.bind(this);
    this.notifyErrorReset = this.notifyErrorReset.bind(this);
    this.notifySuccessReset = this.notifySuccessReset.bind(this);

    var array = [];
    for (var i = 0; i < country_list.length; i++) {
      array[i] = {};
      array[i].value = country_list[i];
      array[i].label = country_list[i];
    }
    country_list = array;
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleChangeCountry(country) {
    this.setState({
      country,
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.Auth.login(this.state.username, this.state.password)
      .then((res) => {
        history.replace("/Newsfeed");
      })
      .catch((err) => {
        this.notifyErrorLogin();
      });
  }

  handleChangeForm(option) {
    const x = option;

    this.setState({
      form: x,
      username: null,
      password: null,
      password1: null,
      password2: null,
      country: null,
      email: null,
      company: null,
      position: null,
      first_name: null,
      last_name: null,
    });
  }

  handleResetPass() {
    const email = this.state.email;

    this.Auth.resetPassword(email)
      .then((res) => {
        this.notifySuccessReset();
      })
      .catch((err) => {
        this.notifyErrorReset();
      });
  }

  handleRegister() {
    const username = this.state.username;
    const password1 = this.state.password1;
    const password2 = this.state.password2;
    const email = this.state.email;
    const country = this.state.country;
    const company = this.state.company;
    const position = this.state.position;
    const first_name = this.state.first_name;
    const last_name = this.state.last_name;

    if (username === null || username === "") {
      this.notifyErrorRegister("The username field may not be blank");
    } else if (first_name === null || first_name === "") {
      this.notifyErrorRegister("The first name field may not be blank");
    } else if (last_name === null || last_name === "") {
      this.notifyErrorRegister("The last name field may not be blank");
    } else if (email === null || email === "") {
      this.notifyErrorRegister("The email field may not be blank");
    } else if (country === null || country === "") {
      this.notifyErrorRegister("The country field may not be blank");
    } else if (company === null || company === "") {
      this.notifyErrorRegister("The company field may not be blank");
    } else if (position === null || position === "") {
      this.notifyErrorRegister("The position field may not be blank");
    } else if (password1 === null || password1 === "") {
      this.notifyErrorRegister("The password field may not be blank");
    } else if (password1.length < 8) {
      this.notifyErrorRegister(
        "The password is too short. It must contain at least 8 characters."
      );
    } else if (password2 === null || password2 === "") {
      this.notifyErrorRegister(
        "You have to verify your password, retype it in the last field"
      );
    } else {
      this.Auth.register3(
        username,
        password1,
        password2,
        email,
        first_name,
        last_name,
        company,
        position,
        country
      )

        .then((x) => {
          if (x.status === 201) {
            this.notifySuccessRegister();
            this.state.form = 1;
            this.state.username = null;
            this.state.password1 = null;
            this.state.password2 = null;
            this.state.email = null;
            this.state.country = null;
            this.state.first_name = null;
            this.state.last_name = null;
            this.state.company = null;
            this.state.position = null;
            this.forceUpdate();
          } else {
            for (var i in x.body) {
              this.notifyErrorRegister(x.body[i]);
            }
          }

          // this.Auth.login(this.state.username, this.state.password1).then(res =>{

          //     history.replace('/Newsfeed');

          // })
        })

        .catch((err) => {
          //this.notifyErrorRegister()
        });
    }
  }
  notifySuccessRegister = () =>
    toast.success(
      "Your register have been successful! You can now log-in to your account :)",
      {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );

  notifyErrorRegister = (name) =>
    toast.error(<ErrorMsg name={name} />, {
      position: "top-right",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  notifyErrorLogin = () =>
    toast.info("Please, recheck your credentials!", {
      position: "top-right",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  notifyErrorReset = () =>
    toast.info("Please recheck the mail address you entered!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  notifySuccessReset = () =>
    toast.success(
      "If the email address you entered exists, you will receive a reset password mail!",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );

  render() {
    if (this.state.form === 1) {
      return (
        <React.Fragment>
          <div>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
          </div>

          <div id="banner">
            <div className="container">
              <div className="sign-up-form">
                <a className="logo">
                  <img
                    src={"public_assets/old_images/FC2_logo_light.png"}
                    alt="Rotate-pro"
                  />
                </a>
                <div>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.twompartners.rotatepro2&hl=en&gl=US"
                    target="_blank"
                  >
                    <img width="100px" src={googlePlay} />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/rotate-pro/id1507651426"
                    target="_blank"
                  >
                    <img width="100px" src={appStore} />
                  </a>
                </div>
                <h2 className="text-white">
                  Connects experts from around the world
                </h2>
                <div className="line-divider"></div>
                <div className="form-wrapper">
                  <p className="signup-text">Login to your account</p>
                  <form action="#">
                    <fieldset className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="username"
                        placeholder="Login"
                        onChange={this.handleChange}
                      />
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        placeholder="Enter a password"
                        onChange={this.handleChange}
                      />
                    </fieldset>
                  </form>

                  <p>
                    By logging in you agree to our terms and{" "}
                    <a href="#/Conditions">conditions</a> .
                  </p>
                  <button className="btn-secondary" onClick={this.handleLogin}>
                    Login
                  </button>
                </div>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleChangeForm(2);
                  }}
                >
                  You don't have an account yet?
                </a>
                <br />
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleChangeForm(3);
                  }}
                >
                  Having problems connecting to your account?
                </a>
                <img
                  className="form-shadow"
                  src="images/bottom-shadow.png"
                  alt=""
                />
              </div>

              <svg className="arrows hidden-xs hidden-sm">
                <path className="a1" d="M0 0 L30 32 L60 0"></path>
                <path className="a2" d="M0 20 L30 52 L60 20"></path>
                <path className="a3" d="M0 40 L30 72 L60 40"></path>
              </svg>
            </div>
          </div>
          <div style={{ backgroundColor: "#26AAE1", padding: 10 }}>
            <div
              style={{
                padding: 18,
                color: "white",
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              Latest articles
            </div>
            <ArticleAccordion />
          </div>
        </React.Fragment>
      );
    } else if (this.state.form === 2) {
      return (
        <React.Fragment>
          <div>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
          </div>
          <div id="banner">
            <div className="container">
              <div className="sign-up-form">
                <a href="/" className="logo">
                  <img
                    src={"public_assets/old_images/FC2_logo_light.png"}
                    alt="Friend Finder"
                  />
                </a>
                <h2 className="text-white">
                  Connects experts from around the world
                </h2>
                <div className="line-divider"></div>
                <div className="form-wrapper">
                  <p className="signup-text">Join us</p>
                  <form action="#">
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="username"
                        placeholder="username"
                        onChange={this.handleChange}
                      />
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="first_name"
                        placeholder="first name"
                        onChange={this.handleChange}
                      />
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="last_name"
                        placeholder="last name"
                        onChange={this.handleChange}
                      />
                    </fieldset>
                    {/* <fieldset className="form-group">
                                                <input type="text" className="form-control" name="country" placeholder="country" onChange={this.handleChange} />
                                            </fieldset> */}
                    <fieldset className="form-group">
                      <Select
                        styles={customStyles}
                        placeholder="Select your country"
                        closeMenuOnSelect={true}
                        components={makeAnimated()}
                        onChange={(e) => this.handleChangeCountry(e.value)}
                        options={country_list}
                      />
                    </fieldset>

                    <fieldset className="form-group">
                      <input
                        type="mail"
                        className="form-control"
                        name="email"
                        placeholder="email"
                        onChange={this.handleChange}
                      />
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="company"
                        placeholder="company name"
                        onChange={this.handleChange}
                      />
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="position"
                        placeholder="position"
                        onChange={this.handleChange}
                      />
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        name="password1"
                        placeholder="Enter a password"
                        onChange={this.handleChange}
                      />
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        name="password2"
                        placeholder="Reenter your password"
                        onChange={this.handleChange}
                      />
                    </fieldset>
                  </form>
                  <p>By registering you agree to our terms and conditions.</p>
                  <button
                    className="btn-secondary"
                    onClick={this.handleRegister}
                  >
                    Register
                  </button>
                </div>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleChangeForm(1);
                  }}
                >
                  Already have an account?
                </a>

                <img
                  className="form-shadow"
                  src="images/bottom-shadow.png"
                  alt=""
                />
              </div>

              <svg className="arrows hidden-xs hidden-sm">
                <path className="a1" d="M0 0 L30 32 L60 0"></path>
                <path className="a2" d="M0 20 L30 52 L60 20"></path>
                <path className="a3" d="M0 40 L30 72 L60 40"></path>
              </svg>
            </div>
          </div>
        </React.Fragment>
      );
    } else if (this.state.form === 3) {
      return (
        <React.Fragment>
          <div>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
          </div>
          <div id="banner">
            <div className="container">
              <div className="sign-up-form">
                <a href="index.html" className="logo">
                  <img
                    src={"public_assets/old_images/FC2_logo_light.png"}
                    alt="Friend Finder"
                  />
                </a>
                <h2 className="text-white">
                  Connects experts from around the world
                </h2>
                <div className="line-divider"></div>
                <div className="form-wrapper">
                  <p className="signup-text">Reset your password</p>
                  <form action="#">
                    <fieldset className="form-group">
                      <input
                        type="mail"
                        className="form-control"
                        name="email"
                        placeholder="email"
                        onChange={this.handleChange}
                      />
                    </fieldset>
                  </form>
                  <p>Send us your email to reset your password.</p>
                  <button
                    className="btn-secondary"
                    onClick={this.handleResetPass}
                  >
                    Send
                  </button>
                </div>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleChangeForm(1);
                  }}
                >
                  Already have an account?
                </a>
                <img
                  className="form-shadow"
                  src="images/bottom-shadow.png"
                  alt=""
                />
              </div>

              <svg className="arrows hidden-xs hidden-sm">
                <path className="a1" d="M0 0 L30 32 L60 0"></path>
                <path className="a2" d="M0 20 L30 52 L60 20"></path>
                <path className="a3" d="M0 40 L30 72 L60 40"></path>
              </svg>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default LoginSection;
