import React, { Component } from "react";

import googlePlay from "../assets/gplay.png";
import appStore from "../assets/appstore.png";

class FooterSection extends Component {
  render() {
    return (
      <React.Fragment>
        <footer id="footer">
          {/* <div className="container">
                        <div className="row">
                        <div className="footer-wrapper">
                            <div className="col-md-3 col-sm-3">
                            <a href=""><img src={'public_assets/old_images/FC2_logo_dark.png'} alt="" className="footer-logo" /></a>
                            <ul className="list-inline social-icons">
                                <li><a href="#"><i className="icon ion-social-facebook"></i></a></li>
                                <li><a href="#"><i className="icon ion-social-twitter"></i></a></li>
                                <li><a href="#"><i className="icon ion-social-googleplus"></i></a></li>
                                <li><a href="#"><i className="icon ion-social-pinterest"></i></a></li>
                                <li><a href="#"><i className="icon ion-social-linkedin"></i></a></li>
                            </ul>
                            </div>
                            <div className="col-md-2 col-sm-2">
                            <h6>For individuals</h6>
                            <ul className="footer-links">
                                <li><a href="">Signup</a></li>
                                <li><a href="">login</a></li>
                                <li><a href="">Explore</a></li>
                                <li><a href="">Finder app</a></li>
                                <li><a href="">Features</a></li>
                                <li><a href="">Language settings</a></li>
                            </ul>
                            </div>
                            <div className="col-md-2 col-sm-2">
                            <h6>For businesses</h6>
                            <ul className="footer-links">
                                <li><a href="">Business signup</a></li>
                                <li><a href="">Business login</a></li>
                                <li><a href="">Benefits</a></li>
                                <li><a href="">Resources</a></li>
                                <li><a href="">Advertise</a></li>
                                <li><a href="">Setup</a></li>
                            </ul>
                            </div>
                            <div className="col-md-2 col-sm-2">
                            <h6>About</h6>
                            <ul className="footer-links">
                                <li><a href="">About us</a></li>
                                <li><a href="">Contact us</a></li>
                                <li><a href="">Privacy Policy</a></li>
                                <li><a href="">Terms</a></li>
                                <li><a href="">Help</a></li>
                            </ul>
                            </div>
                            <div className="col-md-3 col-sm-3">
                            <h6>Contact Us</h6>
                                <ul className="contact">
                                    <li><i className="icon ion-ios-telephone-outline"></i>+971 4 420 1038</li>
                                    <li><i className="icon ion-ios-email-outline"></i>contact@rotate-pro.com</li>
                                <li><i className="icon ion-ios-location-outline"></i>PO48748 Dubai Marina Appart G06</li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div> */}
          <div className="copyright">
            <a href="">
              <img
                src={"public_assets/old_images/FC2_logo_light.png"}
                alt=""
                className="footer-logo"
              />
            </a>
            <div>contact@rotate-pro.com</div>
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.twompartners.rotatepro2&hl=en&gl=US"
                target="_blank"
              >
                <img width="100px" src={googlePlay} />
              </a>
              <a
                href="https://apps.apple.com/us/app/rotate-pro/id1507651426"
                target="_blank"
              >
                <img width="100px" src={appStore} />
              </a>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default FooterSection;
