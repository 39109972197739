import decode from "jwt-decode";

import React, { Component } from "react";

export default class AuthService {
  // Initializing important variables
  constructor(domain) {
    this.domain = domain || "http://apip1.rotate-pro.com"; // API server domain
    this.fetch = this.fetch.bind(this); // React binding stuff
    this.fetchFileForm = this.fetchFileForm.bind(this);
    this.login = this.login.bind(this);
    this.getProfile = this.getProfile.bind(this);
  }

  // login(username, password) {
  //   // Get a token from api server using the fetch api
  //   return this.fetch(`${this.domain}/api/api-token-auth/`, {
  //     method: "POST",
  //     body: JSON.stringify({
  //       username,
  //       password,
  //     }),
  //   }).then((res) => {
  //     this.setToken(res.token); // Setting the token in localStorage
  //     return Promise.resolve(res);
  //   });
  // }

  login(username, password) {
    // Get a token from api server using the fetch api
    //return this.fetch(`http://apip1.rotate-pro.com/api/rest-auth/login/`, {

    return this.fetch(`http://apip1.rotate-pro.com/api/api-token-auth/`, {
      method: "POST",
      body: JSON.stringify({
        username,
        password,
      }),
    }).then((res) => {
      //this.setToken(res.token); // Setting the token in localStorage
      this.setToken(res.token);
      return Promise.resolve(res);
    });
  }

  // Unfollow(url) {
  //     // Get a token from api server using the fetch api
  //     return this.fetch(url, {
  //         method: 'DELETE'
  //     }).then(res => {
  //         return Promise.resolve(res);
  //     })
  // }

  save_views_log(link, user) {
    return this.fetch(`http://apip1.rotate-pro.com/api/views_log/`, {
      method: "POST",
      body: JSON.stringify({
        link,
        user,
      }),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  confirmResetPassword(new_password1, new_password2, uid, token) {
    return this.fetch(
      `http://apip1.rotate-pro.com/api/password/reset/confirm/`,
      {
        method: "POST",
        body: JSON.stringify({
          new_password1,
          new_password2,
          uid,
          token,
        }),
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  postComment(content, user_auth, user_info, post_id) {
    return this.fetch(`http://apip1.rotate-pro.com/api/comments/`, {
      method: "POST",
      body: JSON.stringify({
        content,
        user_auth,
        user_info,
        post_id,
      }),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  resetPassword(email) {
    return this.fetch(`http://apip1.rotate-pro.com/api/password/resetCustom/`, {
      method: "POST",
      body: JSON.stringify({
        email,
      }),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  articleComment(content, user_auth, user_info, article_id) {
    return this.fetch(`http://apip1.rotate-pro.com/api/comment_article/`, {
      method: "POST",
      body: JSON.stringify({
        content,
        user_auth,
        user_info,
        article_id,
      }),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  register(username, password1, password2, email) {
    return this.fetch(
      `http://apip1.rotate-pro.com/api/rest-auth/registration/`,
      {
        method: "POST",
        body: JSON.stringify({
          username,
          password1,
          password2,
          email,
        }),
      }
    )
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch();
  }

  // Users **********************************************************************

  updateUser(body, url) {
    return this.fetch(url, {
      method: "PATCH",
      body: JSON.stringify(body),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  updateComment(content, url) {
    return this.fetch(url, {
      method: "PATCH",
      body: JSON.stringify(content),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  updateProfileInfo(data, url) {
    // Get a token from api server using the fetch api
    return this.fetchFileForm(url, {
      method: "PATCH",
      "Content-Type": "multipart/form-data",
      body: data,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  createUserInfo(user_id) {
    return this.fetch(`http://apip1.rotate-pro.com/api/userInfo/`, {
      method: "POST",
      body: JSON.stringify({
        user_id,
      }),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getUsersList() {
    // Get a token from api server using the fetch api
    return this.fetch("http://apip1.rotate-pro.com/api/Users/", {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getUsers7List() {
    // Get a token from api server using the fetch api
    return this.fetch("http://apip1.rotate-pro.com/api/Users7/", {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getequipement_brands() {
    // Get a token from api server using the fetch api
    return this.fetch("http://apip1.rotate-pro.com/api/equipement_brands/", {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getequipement_status() {
    // Get a token from api server using the fetch api
    return this.fetch("http://apip1.rotate-pro.com/api/equipement_status/", {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getequipement_types() {
    // Get a token from api server using the fetch api
    return this.fetch("http://apip1.rotate-pro.com/api/equipement_types/", {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getAttachementsListByArticle(uid) {
    // Get a token from api server using the fetch api
    return this.fetch(
      "http://apip1.rotate-pro.com/api/article_joint/?article_id=" + uid,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getSkills() {
    // Get a token from api server using the fetch api
    return this.fetch("http://apip1.rotate-pro.com/api/skill/", {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getPredifiinedSkills() {
    // Get a token from api server using the fetch api
    return this.fetch("http://apip1.rotate-pro.com/api/skillPredifined/", {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getPosts() {
    // Get a token from api server using the fetch api
    return this.fetch("http://apip1.rotate-pro.com/api/post/", {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getDomains() {
    // Get a token from api server using the fetch api
    return this.fetch("http://apip1.rotate-pro.com/api/domains/", {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getFollowersByAuthUrl(friend) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/friendsWithoutPaging/?user_id=${friend}&status=1`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getIfFriendSent(friend1, friend2) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/friendsWithoutPaging/?friend1=${friend1}&friend2=${friend2}&status=0`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getNotifsArticleSeenById(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/notif_article/?user_id=${id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getNotifsLikeSeenById(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/notif_like/?user_id=${id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getNotifsPostSeenById(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/notif_post/?user_id=${id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getCommentsByPost(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/comments/?post_id=${id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getCommentsByArticle(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/comment_article/?article_id=${id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getArticleByURL(url) {
    // Get a token from api server using the fetch api
    return this.fetch(`http://apip1.rotate-pro.com/api/articles/${url}/`, {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getStaffList() {
    // Get a token from api server using the fetch api
    return this.fetch("http://apip1.rotate-pro.com/api/Users/?is_staff=true", {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getClientsList() {
    // Get a token from api server using the fetch api
    return this.fetch("http://apip1.rotate-pro.com/api/Users/?is_staff=false", {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getUserById(id) {
    // Get a token from api server using the fetch api
    return this.fetch(`http://apip1.rotate-pro.com/api/Users/${id}/`, {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getUserInfoById(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/userInfo/?user_id=${id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res.results);
    });
  }

  getSkillByName(name) {
    // Get a token from api server using the fetch api
    return this.fetch(`http://apip1.rotate-pro.com/api/skill/?name=${name}`, {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getUserExpById(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/professsional_exp/?user_id=${id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res.results);
    });
  }

  getUserEduById(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/education/?user_id=${id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getUserCertById(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/certification/?user_id=${id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res.results);
    });
  }

  getUserLangById(id) {
    // Get a token from api server using the fetch api
    return this.fetch(`http://apip1.rotate-pro.com/api/habla/?user_id=${id}`, {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res.results);
    });
  }

  getUserSkillById(id) {
    // Get a token from api server using the fetch api
    return this.fetch(`http://apip1.rotate-pro.com/api/skill/?user_id=${id}`, {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getUserPostById(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/post20/?author_id=${id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getUserPost20ById(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/post20/?author_id=${id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  addFollow(friend1, friend2) {
    // Get a token from api server using the fetch api
    return this.fetch(`http://apip1.rotate-pro.com/api/friendsWithoutPaging/`, {
      method: "POST",
      body: JSON.stringify({
        friend1,
        friend2,
        status: 0,
      }),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getUserFriendsById(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/friendsRequests/?friend=${id}&status=1`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getSkillsByNames(names) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/skillByNames/?names=${names}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  // getUserFriendsInvitById(id) {
  //     // Get a token from api server using the fetch api
  //     return this.fetch(`http://apip1.rotate-pro.com/api/friendsWithoutPaging/?user_id=${id}&status=0`, {
  //         method: 'GET',

  //     }).then(res => {
  //
  //         return Promise.resolve(res);
  //     })
  // }

  getUserFriendsRequestsById(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/friendsRequests/?friend=${id}&status=0`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getUserFriendsById(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/friendsRequests/?friend=${id}&status=1`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getArticleBaughtWithFilter(user_id, article_id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/baught/?user_id=${user_id}&article_id=${article_id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getArticleByContent(search) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/articlesByKeywords/?domains=${search}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getArticleBaughtForUser(user_id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/baught/?user_id=${user_id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getArticleBaught20ForUser(user_id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/baught20/?user_id=${user_id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  searchUsersByFirst_name(name) {
    // Get a token from api server using the fetch api
    return this.fetch(`http://apip1.rotate-pro.com/api/Users/?search=${name}`, {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  // END Users **********************************************************************

  // articles ***********************************************************************

  publishArticle(author_id, title, small_description, content, keywords) {
    return this.fetch(`http://apip1.rotate-pro.com/api/articles/`, {
      method: "POST",
      body: JSON.stringify({
        title,
        small_description,
        content,
        keywords,
        author_id,
        price: 0,
        files: 0,
      }),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  postEquipementDetails(
    article_id,
    e_type,
    e_brand,
    e_model,
    e_serial_number,
    e_status,
    e_owner,
    comment
  ) {
    return this.fetch(`http://apip1.rotate-pro.com/api/equipement_details/`, {
      method: "POST",
      body: JSON.stringify({
        article_id,
        e_type,
        e_brand,
        e_model,
        e_serial_number,
        e_status,
        e_owner,
        e_comment: comment,
      }),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  updateArticle(author_id, title, small_description, content, keywords, url) {
    return this.fetch(url, {
      method: "PATCH",
      body: JSON.stringify({
        title,
        small_description,
        content,
        keywords,
        author_id,
        price: 0,
        files: 0,
      }),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  updatePost(url, author_id, content) {
    return this.fetch(url, {
      method: "PATCH",
      body: JSON.stringify({
        url,
        author_id,
        content,
        media_type: "simple",
      }),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getArticlesList() {
    // Get a token from api server using the fetch api
    return this.fetch("http://apip1.rotate-pro.com/api/articles/", {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getArticlesListByDomain(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      "http://apip1.rotate-pro.com/api/articlesWithPaging/?keywords=" + id,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getEquipmentDetailsByid(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      "http://apip1.rotate-pro.com/api/equipement_details/?article_id=" + id,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getArticlesWithPagingList() {
    // Get a token from api server using the fetch api
    return this.fetch("http://apip1.rotate-pro.com/api/articlesWithPaging/", {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getArticles20() {
    // Get a token from api server using the fetch api
    return this.fetch("http://apip1.rotate-pro.com/api/articles20/", {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getArticles20ByAuthorId(author_id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      "http://apip1.rotate-pro.com/api/articles20/?author_id=" + author_id,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getHiddenArticlesList() {
    // Get a token from api server using the fetch api
    return this.fetch("http://apip1.rotate-pro.com/api/articles/?hidden=true", {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getOnlineArticlesList() {
    // Get a token from api server using the fetch api
    return this.fetch(
      "http://apip1.rotate-pro.com/api/articles/?hidden=false",
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getArticlesListByAuthorId(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/articles/?author_id=${id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getArticles20ListByAuthorId(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/articles20/?author_id=${id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  // End articles ***********************************************************************

  // postUpload *******************************************************************

  savePost(data) {
    // Get a token from api server using the fetch api
    return this.fetchFileForm(`http://apip1.rotate-pro.com/api/post/`, {
      method: "POST",
      "Content-Type": "multipart/form-data",
      body: data,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  saveAttachement(data) {
    // Get a token from api server using the fetch api
    return this.fetchFileForm(
      `http://apip1.rotate-pro.com/api/article_joint/`,
      {
        method: "POST",
        "Content-Type": "multipart/form-data",
        body: data,
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  ChangeProfilePicture(data, url) {
    // Get a token from api server using the fetch api
    return this.fetchFileForm(url, {
      method: "PATCH",
      "Content-Type": "multipart/form-data",
      body: data,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getPostLikes(uid) {
    // Get a token from api server using the fetch api
    return this.fetch(`http://apip1.rotate-pro.com/api/likes/?post_id=${uid}`, {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res.results);
    });
  }

  getPostLikes20(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/likes20/?user_id=${id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getArticleLikes(uid) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/likes_articles/?article_id=${uid}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getArticleLikes20(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/likes_articles20/?user_id=${id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getArticleLikesById(id, uid) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/likes_articles/?user_id=${id}&article_id=${uid}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  addPostLike(user_id, post_id) {
    // Get a token from api server using the fetch api
    return this.fetch(`http://apip1.rotate-pro.com/api/likes/`, {
      method: "POST",
      body: JSON.stringify({
        user_id,
        post_id,
      }),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  addBoughtArticle(user_id, article_id) {
    // Get a token from api server using the fetch api
    return this.fetch(`http://apip1.rotate-pro.com/api/baught/`, {
      method: "POST",
      body: JSON.stringify({
        user_id,
        article_id,
      }),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  addArticleLike(user_id, article_id) {
    // Get a token from api server using the fetch api
    return this.fetch(`http://apip1.rotate-pro.com/api/likes_articles/`, {
      method: "POST",
      body: JSON.stringify({
        user_id,
        article_id,
      }),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  addCommentLike(user_id, comment_id) {
    // Get a token from api server using the fetch api
    return this.fetch(`http://apip1.rotate-pro.com/api/likes_comments/`, {
      method: "POST",
      body: JSON.stringify({
        user_id,
        comment_id,
      }),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  addCommentArticleLike(user_id, comment_id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/likes_comments_articles`,
      {
        method: "POST",
        body: JSON.stringify({
          user_id,
          comment_id,
        }),
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getPostLiked(uid, user_id) {
    // Get a token from api server using the fetch api

    return this.fetch(
      `http://apip1.rotate-pro.com/api/likes/?post_id=${uid}&user_id=${user_id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  // end postUpload *******************************************************************

  // messages ************************************************************************

  getMessagesSentById(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/messages/?sender_id=${id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getLikesCommentsArticlesByComment(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/likes_comments_articles/?comment_id=${id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getLikesCommentsByComment(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/likes_comments/?comment_id=${id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  getMessagesReceivedById(id) {
    // Get a token from api server using the fetch api
    return this.fetch(
      `http://apip1.rotate-pro.com/api/messages/?receiver_id=${id}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  sendMessage(msg, sender_id, receiver_id) {
    // Get a token from api server using the fetch api
    return this.fetch(`http://apip1.rotate-pro.com/api/messages/`, {
      method: "POST",
      body: JSON.stringify({
        sender_id,
        receiver_id,
        msg,
      }),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  // end messages *******************************************************************

  // updates /****************************************************************** */

  updateProfile(body, url) {
    // Get a token from api server using the fetch api

    return this.fetch(url, {
      method: "PATCH",
      body: JSON.stringify(body),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  acceptInvit(url) {
    // Get a token from api server using the fetch api

    return this.fetch(url, {
      method: "PATCH",
      body: JSON.stringify({
        status: 1,
      }),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  // end updates /****************************************************************** */

  // profile **********************************************************************************************

  addExperience(body) {
    // Get a token from api server using the fetch api

    return this.fetch("http://apip1.rotate-pro.com/api/professsional_exp/", {
      method: "POST",
      body: JSON.stringify(body),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }
  addEducation(body) {
    // Get a token from api server using the fetch api

    return this.fetch("http://apip1.rotate-pro.com/api/education/", {
      method: "POST",
      body: JSON.stringify(body),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  addCertification(body) {
    // Get a token from api server using the fetch api

    return this.fetch("http://apip1.rotate-pro.com/api/certification/", {
      method: "POST",
      body: JSON.stringify(body),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  addSkill(body) {
    // Get a token from api server using the fetch api

    return this.fetch("http://apip1.rotate-pro.com/api/skill/", {
      method: "POST",
      body: JSON.stringify(body),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  addLanguage(body) {
    // Get a token from api server using the fetch api

    return this.fetch("http://apip1.rotate-pro.com/api/habla/", {
      method: "POST",
      body: JSON.stringify(body),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  deleteEntry(url) {
    // Get a token from api server using the fetch api

    return this.fetch(url, {
      method: "DELETE",
    }).then((res) => {
      //return Promise.resolve(res);
    });
  }

  deletePost(url) {
    // Get a token from api server using the fetch api

    return this.fetch(url, {
      method: "DELETE",
    }).then((res) => {
      //return Promise.resolve(res);
    });
  }

  deleteInvit(url) {
    // Get a token from api server using the fetch api

    return this.fetch(url, {
      method: "DELETE",
    }).then((res) => {
      //return Promise.resolve(res);
    });
  }

  // end profile *************************************************************************************

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken(); // GEtting token from localstorage

    return !!token && !this.isTokenExpired(token); // handwaiving here
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired. N
        return true;
      } else return false;
    } catch (err) {
      return false;
    }
  }

  setToken(idToken) {
    // Saves user token to localStorage
    localStorage.setItem("id_token", idToken);
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem("id_token");
  }

  logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem("token");
    localStorage.removeItem("jwt");
    localStorage.removeItem("id_token");
  }

  getProfile() {
    // Using jwt-decode npm package to decode the token
    return decode(this.getToken());
  }

  fetch(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    if (this.loggedIn()) {
      headers["authorization"] = "JWT " + this.getToken();
    }

    //.replace('http', 'https')
    return fetch(url.replace("http", "https"), {
      mode: "cors",
      headers,
      ...options,
    })
      .then(this._checkStatus)
      .catch()
      .then((response) => {
        try {
          if (response.status != 204) {
            return response.json();
          } else return response;
        } catch (err) {
          return response;
        }
      })
      .catch();
  }

  Unfollow(url) {
    // performs api calls sending the required authentication headers
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    if (this.loggedIn()) {
      headers["authorization"] = "JWT " + this.getToken();
    }
    //.replace('http', 'https')
    return fetch(url.replace("http", "https"), {
      mode: "cors",
      method: "DELETE",
      headers,
    })
      .then(this._checkStatus)
      .catch();
  }

  register2(username, password1, password2, email) {
    //https
    return fetch("http://apip1.rotate-pro.com/api/rest-auth/registration/", {
      mode: "cors",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password1,
        password2,
        email,
      }),
    })
      .then((response) => {
        return response;
      })
      .catch();
  }

  register3(
    username,
    password1,
    password2,
    email,
    first_name,
    last_name,
    company,
    position,
    country
  ) {
    //https
    return (
      fetch("https://apip1.rotate-pro.com/api/rest-auth/registration1/", {
        mode: "cors",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password1,
          password2,
          email,
          first_name,
          last_name,
          company,
          position,
          country,
        }),
      })
        .then((response) => {
          var decoder = new TextDecoder();
          var reader = response.body.getReader();

          var buffer = "";

          return reader.read().then(function process(result) {
            if (result.done) {
              //return buffer
            }

            buffer += decoder.decode(result.value, { stream: true });

            //console.log(buffer)
            return { body: JSON.parse(buffer), status: response.status };

            // keep reading
            //return reader.read().then(process);
          });
        })
        //.then(res => {return res})
        .catch()
    );
  }

  fetchFileForm(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {
      Accept: "application/json",
      // 'Content-Type': 'application/json'
    };

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    if (this.loggedIn()) {
      headers["authorization"] = "JWT " + this.getToken();
    }
    //.replace('http', 'https')
    return fetch(url.replace("http", "https"), {
      mode: "cors",
      headers,
      ...options,
    })
      .then(this._checkStatus)
      .then((response) => response.json());
  }

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      return response;
    } else {
      var error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }
}
