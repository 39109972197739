import React, { Component } from 'react';
import { createHashHistory } from 'history'

import AuthService from '../../components/AuthService';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const history = createHashHistory()
const Auth = new AuthService()


class resetPassword extends Component{

    constructor(){
        super();
        this.state = {
            form : 1,
            uid: null,
            token : null,
            new_password1: null,
            new_password2: null
        }

        this.handleChange = this.handleChange.bind(this)
        this.sendNewPass = this.sendNewPass.bind(this)

    }

    componentWillMount(){
       // console.log(this.props.match.params)
        this.setState({
            uid : this.props.match.params.one,
            token : this.props.match.params.two
        })
    }

    handleChange(e){
 
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    async sendNewPass(){
        const new_password1 = this.state.new_password1
        const new_password2 = this.state.new_password2
        const uid = this.state.uid
        const token = this.state.token
        Auth.confirmResetPassword(new_password1, new_password2, uid, token)
        .then(res=>{
            this.notifySuccessReset()
            this.interval = setInterval(()=>{ this.props.history.push(`/Login`) }, 3000);

        })
        .catch(err=>{
            this.notifyErrorReset()
        })
    }
    componentWillUnmount() {
        clearInterval(this.interval);
      }

    notifyErrorReset = () => toast.info('Please recheck your password or the url you entered with!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
        });

        notifySuccessReset = () => toast.success('You can login now with the new password! :)', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
            });

    

    render(){
        return(
            <React.Fragment>

                    <ToastContainer
                       position="top-right"
                       autoClose={5000}
                       hideProgressBar={false}
                       newestOnTop
                       closeOnClick
                       rtl={false}
                       pauseOnVisibilityChange
                       draggable
                       pauseOnHover
                       />

                <div id="banner">
                    <div className="container">
                        <div className="sign-up-form">
                                    <a href="index.html" className="logo"><img src={'public_assets/old_images/FC2_logo_light.png'} alt="Rotate-pro"articles/></a>
                                    <h2 className="text-white">Connects experts from around the world</h2>
                                    <div className="line-divider"></div>
                                    <div className="form-wrapper">
                                        <p className="signup-text">Reset your password</p>
                                        <form action="#">
                                            <fieldset className="form-group">
                                                <input type="password" className="form-control" name="new_password1" placeholder="Enter a password" onChange={this.handleChange} />
                                            </fieldset>
                                            <fieldset className="form-group">
                                                <input type="password" className="form-control" name="new_password2" placeholder="Re-enter your password" onChange={this.handleChange} />
                                            </fieldset>
                                        </form>
                                        
                                        <p>Type your password twice to reset it.</p>
                                        <button className="btn-secondary" onClick={this.sendNewPass}>Reset</button>
                                    </div>
                                    <a href="/#/Login">You want to login?</a><br />
                                    <img className="form-shadow" src="images/bottom-shadow.png" alt="" />
                                </div>
    
                        <svg className="arrows hidden-xs hidden-sm">
                        <path className="a1" d="M0 0 L30 32 L60 0"></path>
                        <path className="a2" d="M0 20 L30 52 L60 20"></path>
                        <path className="a3" d="M0 40 L30 72 L60 40"></path>
                        </svg>
                    </div>
                </div>
                </React.Fragment>
        )
        
        }
        
    }
    
export default resetPassword;