import React, { Component } from "react";
import LoginSection from "./login";
import FooterSection from "./footerSection";

class Home extends Component {
  render() {
    return (
      <div>
        <LoginSection />
        {/* <FeaturesSection />
                <ImageDivider />
                <FactsSection /> */}
        <FooterSection />
      </div>
    );
  }
}

export default Home;
